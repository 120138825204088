td.rowstyle {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
}
td.rowstyle.eng {
    padding-top: 10%!important;
}
td.rowstyle.mth {
    padding-top: 7%!important;
}
h1.border-custom:after {
    content: '';
    position: absolute;
    background-color: grey;
    width: 22%;
    height: 3px;
    top: 100%;
    left: 39%;
}
p.ptag img {
    width: 20px;
    margin-top: -5px;
    margin-right: 5px;
}

.wrap-result {
    background: #000000;
}




/* Email Template Start */

.word-break {
    word-break: break-word;
}
.header-e.row {
    background: #000000;
    padding: 10px;
}
.header-e.row img {
    width: 149px;
    max-width: 100%;
}
.name-section.row {
    background: #e3cf8a;
    padding: 15px;
}
.name-section.row p {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
}
.mheading {
    font-size: 24px;
    line-height: 31px;
    font-weight: 600;
    padding: 10px 0px;
}
.emailTemplateMain table tr th {
    color: #000000!important;
    font-weight: 700;
    font-size: 18px;
}
.table-bg-gray {
    background: #efefef;
    padding: 0px 15px;
}
.mistakes p {
    font-weight: 600;
    font-size: 14px;
}
.bg-gray {
    background: #999999;
}
img.hloimg {
    width: 45px!important;
    max-width: 100%;
}
img.pieimg {
    width: 800px;
    max-width: 100%;
}
.secheadng {
    font-weight: bold;
}
.secheadng:after {
    content: '';
    position: absolute;
    background-color: #000000;
    width: 26%;
    height: 3px;
    top: 100%;
    left: 37%;
}

/* Email Template End */