  body {
    font-family: 'Lato',Helvetica,Arial,sans-serif!important;
  }
  h1, h2, h3, h4, h5, h6, span, div, a, p {
    font-family: 'Lato',Helvetica,Arial,sans-serif!important;
  }
  table, th, tr, td {
    font-family: 'Lato',Helvetica,Arial,sans-serif!important;
  }
  button, input {
    font-family: 'Lato',Helvetica,Arial,sans-serif!important;
  }
  li {
    font-family: "Lato",Helvetica,Arial,sans-serif!important;
  }
  label{
    font-family: "Lato",Helvetica,Arial,sans-serif!important;
  }
  .btn-primary {
    color: #fff!important;
    background-color: #c83232!important;
    border-color: #c83232!important;
    border: 1px solid #c83232!important;
}
  .width-100 {
    width: 100%!important;
  }
  .opacity-6 {
    opacity: 0.6;
  }
  .color-white {
    color: #ffffff;
  }
  .color-black {
    color: #000000;
  }
  .bg-red{
    background: #c83232;
  }
  .color-gold{
    color: #e0b270;
  }
  .color-red{
    color: #c83232;
  }
  .bg-black {
    background: #000000;
  }
  .bg-white {
    background: #ffffff;
  }
  table.table-dark {
    background: #000000;
    color: #ffffff;
  }
  .border-none {
    border: none;
  }
  .table thead th {
    border-width: 0 0 2px;
    color:#cca267;
  }
  .table th {
    color: #cca267;
  }
  h1 {
    font-size: 27px;
    font-weight: 400;
    color: #000000;
  }
  h2 {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    opacity: 0.8;
  }
  h5 {
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    opacity: 0.7;
  }
  .button-cp {
    background: #c83232;
    color: #fff;
    padding: 12px 30px;
    border-radius: 4px;
    font-size: 20px;
    text-decoration: none;
    font-weight: 600;
  }
  .button-cp:hover {
    text-decoration: none;
    color: #ffffff;
    background: #d64141;
  }
  table tr td, th {
    border: 1px solid #808080!important;
    padding: 10px 0px!important;
  }
  .wrap-main-section {
    padding-top: 3.9%;
  }
  .wrap-header {
    z-index: 999;
    position: fixed;
    width: 100%;
  }
  button{
    outline: none!important;
  }
  button:hover {
    background: #c83232!important;
  }
  .font-bolder{
    font-size: 38px;
    font-weight: 800;
  }
  