.alert.alert-info {
    font-family: Lato, Helvetica, Arial, sans-serif !important;
    z-index: 999999 !important;
}

.alert.alert-info {
    font-family: Lato, Helvetica, Arial, sans-serif !important;
    z-index: 999999 !important;
}

.alert {
    width: 100% !important;
    bottom: 0px !important;
    position: fixed !important;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0px !important;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    z-index: 999 !important;
}

.alert-danger {
    color: #ffffff !important;
    background-color: #c83232 !important;
    border-color: #f5c6cb;
    text-align: center;
    border: none;
    border-radius: 0px;
}

.alert-info {
    color: #000000 !important;
    background-color: #f1e295 !important;
    text-align: center !important;
}

.alert-danger {
    z-index: 100 !important;
}