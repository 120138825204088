.pdf-sec.col {
    height: 1000px;
    overflow: auto;
    padding: 0px 0px;
}
.option-sec.col {
    height: 1000px;
    overflow: auto;
    border-left: 3px double #808080;
    scroll-behavior: auto;
}
.continue-button.col {
    padding: 15px;
    text-align: center;
}
.option-sec.col h5 {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
}
p.scale-btns {
    background: #808080;
    text-align: center;
    padding: 5px 0px;
}
p.scale-btns button {
    text-align: center;
    background: #ececed;
    border: 1px solid #ececed;
    margin: 0px 10px;
    border-radius: 100%;
    width: 3%;
    max-width: 100%;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    outline: none;
}


@media only screen and (max-width: 375px) {
.pdf-sec.col {
    height: 1000px;
    overflow: auto;
    padding: 108px 0px;
}
p.scale-btns button {
    width: 8%;
}

}

@media only screen and (max-width: 768px) {
    .pdf-sec.col {
        height: 1000px;
        overflow: auto;
        padding: 60px 0px;
    }
    p.scale-btns button {
        width: 5%;
    }
    
    }