.bg-img {
    background-image: url'("/background-404.jpg")';
    background-size: cover;
    background-position: center;
}
.error-sec.col img {
    max-width: 100%;
}
.lgodone img {
    max-width: 100%;
    width: 50px;
}
.support {
    text-decoration: none;
}