/*.band {
    background: linear-gradient(35deg, #eada8f 80%, #999595 50%);
}*/
.band {
    border: 2px solid #eada8f;
}
thead tr {
    background: #808080;
}
table thead tr th{
    color: #000000!important;
    font-weight: 700;
    font-size: 18px;
}
table tr th {
    color: #ffffff!important;
    font-weight: 700;
    font-size: 18px;
}
p.ptag {
    font-weight: 600;
}
p.ptag span {
    color: #eada8f;
}
.writing-ins-table .table thead th {
    font-size: 25px;
}
.writing-ins-table .table td {
    font-size: 22px;
}
.instruction-wrap {
    padding-top: 60px;
}
.direction-sec p span {
    font-size: 20px;
    font-weight: 600;
}