.thank-logo.col img {
    width: 71px;
    max-width: 100%;
}
.thank-text.mt-5.mb-5.col h1 {
    font-weight: 700;
    font-size: 26px;
    color: #7f7f7f;
}
.thank-book-button.col a {
    background: #000;
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 20px;
}

/*    Test Summary   */

.test-summary-heading.col h1 {
    font-size: 30px;
    font-weight: 700;
    color: #000000;
}
h2.sat-diag {
    font-size: 24px;
    font-weight: 600;
}
.mailsent.col a {
    background: #808080;
    color: #fff;
    padding: 2px 5px;
    text-decoration: none;
}
.color-white {
    color: #fff!important;
}

.wrap-thank {
    background: #000000;
}

/*    Ticker    */

.ticker.col {
    font-size: 15px;
    font-family: "Lato",Helvetica,Arial,sans-serif!important;
    font-weight: 500;
    color: #fff;
    background: #a02828;
    padding-top: 0.5rem;
}
.ticker.col p {
    margin-bottom: 0.3rem!important;
}