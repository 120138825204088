.fade.sure-modal.modal.show .modal-title.h4 {
    font-size: 20px;
    font-weight: 600;
    opacity: 0.9;
    color: #ffffff;
    font-family: 'Lato',Helvetica,Arial,sans-serif!important;
}
.fade.sure-modal.modal.show .modal-body {
    font-size: 14px!important;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    opacity: 0.6!important;
}
.fade.sure-modal.modal.show .modal-footer {
    display: block;
    text-align: center;
}
.fade.sure-modal.modal.show .modal-content {
    background: #191c21;
}
.fade.sure-modal.modal.show .modal-footer button {
    background: #c83232;
    border: #c83232;
    width: 20%;
    outline: none;
}
.btn-primary.focus, .btn-primary:focus {
    box-shadow: transparent!important;
}