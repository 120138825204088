.lheading {
    font-weight: 600;
    font-size: 26px;
    color: #000000;
}
.lform img {
    max-width: 100%;
    width: 50px;
}
.lform label {
    display: inline-block;
    margin-bottom: .5rem;
    font-size: 12px;
    letter-spacing: 1px;
    color: #000 !important;
}
.lform input {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.05rem;
    padding: 25px 15px;
    border-radius: 10px;
    margin: 0px 10px 0px 0px;
    width: 100% !important;
    background: #ffffff !important;
    color: #4b4b4b !important;
    border: 1px solid #a1abb8 !important;
}
.lform button {
    font-size: 18px;
    padding: 12px 30px !important;
    border-radius: 12px;
    background: #0071e3!important;
    border: 1px solid #0071e3!important;
    color: #ffffff;
    font-weight: 600;
    margin: 15px 0px 5px 0px;
    width: 100%;
    letter-spacing: 0.04rem;
}