
  .fholo {
    width: 35px;
    max-width: 100%;
  }
  .flogo {
    width: 100px;
    max-width: 100%;
  }
  /*.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}*/
  .display-content {
    display: contents!important;
  }
  .footer {
    width: 100%;
    position: absolute;
    left: 0;
    margin-top: auto;
  }

  .top-footer {
    border-top: 1px solid;
  }
  .top-footer li {
    list-style-type: none;
    padding-bottom: .4rem;
    margin-bottom: 1rem;
    text-decoration: none;
    cursor: pointer;
}
.ftr-menu-h {
  opacity: .6!important;
  color: #fff;
  font-weight: 700;
  line-height: 1.25;
  font-size: 14px;
  text-rendering: optimizeLegibility;
  margin-bottom: 1.4rem;
  letter-spacing: .03em;
}
.top-footer li a {
    color: #fff;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .01em;
    text-decoration: none;
    font-family: "Lato",Helvetica,Arial,sans-serif!important;
}
p.copy-sec-img {
  margin-top: 5px;
}
p.copyright {
  margin-top: 5px;
  font-size: 14px!important;
}
.pl-0 i.fa {
  margin-right: 15px;
}


/*  new footer UI  */

.ug-top-footer {
  padding: 100px 0px;
}
.ug-top-footer .ugFooterBrand {
    border-radius: 7px;
}
.ug-top-footer .ugFooterHolo {
    padding-right: 5%;
}
.ug-footer-menu .footerSocialMedia {
    padding-right: 30%;
}
.ug-footer-menu .footerAboutList {
    padding-right: 30%;
}
.ug-footer-menu {
  margin-bottom: 5%;
  margin-left: 10%;
}
.ug-footer-menu p {
    font-size: 1.2vw;
    letter-spacing: 0.03rem;
    font-weight: 600;
    line-height: 1vw;
    margin-bottom: 24px;
    opacity: 0.9;
}
.ug-footer-menu ul {
    padding-left: 0px!important;
}
.ug-footer-menu li {
  color: inherit;
  font-size: 1.2vw;
  letter-spacing: 0.75px;
  line-height: 1.8vw;
  margin-bottom: 10px;
  display: block;
  opacity: 0.7;
  list-style-type: none;
}
.ug-footer-menu a {
    color: #ffffff;
}
  .ug-footer-logo-holo {
      margin-bottom: 10px;
      margin-left: -15px;
  }
  .ug-footer-logo-holo img {
      padding-left: 10%!important;
  }
  .ug-logos-section-text-top h6 {
          letter-spacing: 0.03rem;
          line-height: 1vw;
          margin-bottom: 24px;
          opacity: 0.9;
          font-size: 1.5vw;
          font-weight: 600;
          margin-top: 7%;
      }
  .ug-logos-section-text-bottom {
      max-width: 500px;
  }
  .ug-logos-section-text-bottom p {
      font-size: 1.2vw;
      letter-spacing: 0.03rem;
      line-height: 1.8vw;
      margin-bottom: 75px;
      opacity: 0.7;
  }
.ug-bottom-footer {
  padding: 0px 0px 100px 0px;
}
.ug-bottom-footer .ugCopyRight {
      align-self: baseline;
}
.ug-bottom-footer .ugCopyRight p {
  color: #ffffff;
  opacity: 0.5;
  letter-spacing: 0.03rem;
  font-size: 1.2vw;
}
  .ug-copy-right-r {
      text-align: right;
      padding-right: 5%;
  }
  .ug-copy-right-r a {
      font-size: 1.2vw;
      letter-spacing: 0.03rem;
      line-height: 1vw;
      color: rgb(255, 255, 255);
      opacity: 0.7;
      margin-bottom: 20px;
      padding: 0px 15px;
      border-right: 1px solid rgb(246, 246, 246);
  }
/*
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .ugFooterLogoHolo {
      margin-left: 0px;
  }
  .ugTopFooter {
      padding: 70px 15px 35px 15px;
      .ugFooterMenu {
          margin-bottom: 10%;
          margin-left: 0%;
          .footerSocialMedia {
              padding-right: 0%;
              padding-left: 0;
          }
          .footerAboutList {
              padding-right: 0%;
              padding-left: 0;
          }
          p {
              font-size: 4vw;
          }
          li {
              font-size: 3.5vw;
              letter-spacing: 0.75px;
              line-height: 5.8vw;
          }
      }
      .ugLogosSectionTextTop {
          h6 {
              line-height: 22px;
              font-size: 4.5vw;
          }
      }
      .ugLogosSectionTextBottom {
          p {
              font-size: 3.5vw;
              letter-spacing: 0.03rem;
              line-height: 5.5vw;
              margin-bottom: 75px;
          }
      }
  }
  .ugBottomFooter {
      padding: 70px 0px 70px 0px;
      .ugCopyRight {
          align-self: baseline;
          p {
              color: #ffffff;
              opacity: 0.5;
              letter-spacing: 0.03rem;
              font-size: 16px;
              text-align: center;
          }
      }
      .ugCopyRightR {
          text-align: center;
          padding-right: 8%;
          a {
              font-size: 18px;
              letter-spacing: 0.03rem;
              line-height: 34px;
              color: rgb(255, 255, 255);
              opacity: 0.7;
              margin-bottom: 20px;
              padding: 0px 15px;
              border-right: 1px solid rgb(246, 246, 246);
          }
      }
  }
  
}
/* new footer section ended */