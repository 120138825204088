.logo img {
    width: 35px;
    max-width: 100%;
    padding: 5px 0px;
  }
  .timer.col h5 {
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
}
.headerbtn.col a {
  padding: 5px 10px 0px 10px;
  border-radius: 4px;
  text-decoration: none;
  border: 1px solid #fff;
}
h2.english-test-header {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
h2.english-test-header span {
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
}

/*    new updation  */

.header.bg-black.col {
  -webkit-box-shadow: 0 8px 6px -8px #808080;
	   -moz-box-shadow: 0 8px 6px -8px #808080;
	        box-shadow: 0 8px 6px -8px #808080;
}
.instruction-wrap {
  background: #000000;
}
.navbar a {
  color: #ffffff!important;
  font-size: 13px;
  color: #ffffff !important;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
.dropdown-menu {
  background-color: #191c21!important;
}
.dropdown-item:focus, .dropdown-item:hover {
  text-decoration: none;
  background-color: transparent!important;
}
span.countdown-timer {
  font-weight: 600;
  margin-top: -3px;
  margin-left: 5px;
  color: #f0e79d;
  opacity: 99!important;
  font-size: 20px;
}
.dropdown-menu.show a {
  color: #fff!important;
  font-size: 8.5px!important;
  padding: 8px 18px;
  margin-left: 0;
  font-weight: 600!important;
  letter-spacing: .12em!important;
  line-height: normal!important;
  opacity: .6!important;
  text-transform: uppercase!important;
}
.dropdown-menu.show {
  margin-top: 5px!important;
  border-radius: 0px;
}
.dropdown-item.active, .dropdown-item:active {
  text-decoration: none;
  background-color: transparent!important;
}
.navbar-light .navbar-toggler {
  color: #ffffff!important;
  border-color: #ffffff!important;
}
button.navbar-toggler.collapsed {
  background: #ffffff;
}
/*.navbar-light .navbar-toggler-icon {
  background-image: url('./download.png')!important;
}*/

@media only screen and (max-width: 375px) {
.button-cp {
  font-size: 13px!important;
}

}

@media only screen and (max-width: 414px) {
  .button-cp {
    font-size: 13px!important;
  }
  
  }
  
@media only screen and (max-width: 360px) {
  .button-cp {
    font-size: 12px!important;
  }
  
  }


  /*   new UI Header   */

  .header-strip-new {
    background-color: rgb(15, 15, 15);
  }
  /*a#ug-menu-adm-adv .welcomeDropdown {
    background: none!important;
    color: #FFE55C!important;
    font-weight: 600!important;
  }*/

  .ug-header {
    padding-right: 0;
    padding-left: 0;
    padding-top: 20px;
  }
  .ug-header .afterLoginDropIcon {
      margin-top: 20px;
      margin-left: -30px;
  }
  .ug-header .ugHolo {
        margin-bottom: 0;
        padding-left: 30%;
    }
  .ug-header .ugLogo {
        margin-bottom: 0;
    }

  .ug-strip {
    background-color: rgb(39, 39, 39);
  }
  .ug-strip p {
        letter-spacing: 0.03rem;
        color: #ffffff;
        text-align: center;
        margin-top: 1.1%;
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize!important;
  }
  .ug-strip a {
            font-size: 22px;
            line-height: 25px;
            color: #FFE55C;
            opacity: 0.9;
            margin-left: 15px;
  }
  .ug-strip i {
                margin-left: 5px;
                font-size: 16px;
            }
  .login {
      background: #FFE55C;
      color: #000000;
      padding: 7px 15px;
      border-radius: 50px;
      margin-top: -5px;
  }
  .custom-navbar {
    position: absolute;
    right: 0;
    top: 25%;
  }
  
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
.header_ugHeader__VsIQA.col a#basic-nav-dropdown:hover .dropdown-menu {
  display: block!important;
}
.dropdown-menu {
  top: 100%!important;
  left: -28%!important;
  padding: 0;
  margin-top: 0.125rem;
}
.custom-css-nav.dropdown.nav-item .dropdown-menu {
  top: 100%!important;
  left: -63%!important;
  padding: 0;
  margin-top: 0.125rem;
}
.custom-navbar dropdown-toggle::after {
  right: 6%;
  content: "";
  position: absolute;
  top: 5%;
  height: 50%;
  width: 18px;
  border-radius: 0 5px 5px 0;
  border-top: 0;
  pointer-events: none;
  background-image: url'(./download-drop.png)';
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
a.dropdown-item::after {
  content: " ";
  position: absolute;
  right: 5px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #191c21;
}
a.dropdown-item {
  font-size: 13px!important;
  border-bottom: 1px solid #ffffff33!important;
  color: #ffffff !important;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  opacity: 0.8;
  padding: 20px 15px !important;
}
  /*
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    .ugHeader {
      padding-right: 3%;
      padding-left: 3%;
      padding-top: 0px;
    }
    .ugStrip {
      background-color: rgb(39, 39, 39);
      p {
          margin-top: 0%;
          font-size: 14px;
          padding-top: 10px;
          padding-bottom: 0px;
          a {
            font-size: 16px;
            line-height: 25px;
            color: #FFE55C;
            opacity: 0.9;
            margin-left: 5px;
          }
      }
    }
    .Login {
      text-align: center;
      background: #FFE55C;
      color: #000000;
      padding: 7px 15px;
      border-radius: 50px;
      margin-top: 0px;
      margin-bottom: 0;
      width: 50%;
    }
  }
  
  
  
  /* new menu section ended */
  /*
  .afterLoginHeader {
    background: #000000;
  }
  .beforeLoginHeader {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background: #000000;
    .headerHolo {
      width: 35px;
      height: 35px;
    }
    .login {
      padding: 0.7rem 1.5rem !important;
      border-radius: 4px;
      background: #c83232;
    }
  }
  .welcomeHeader {
    color: #ffffff;
    font-size: 11px;
    letter-spacing: 0.09rem;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 500;
  }
  
  $small: 450px;
  
  .app {
    padding: 0.7rem 1.2rem !important;
    border-radius: 4px;
    background: #ffffff;
    margin-right: -20px;
    @media screen and (min-width: $small) {
      //display none
      display: none;
    }
  }
  @media only screen and (max-width: 600px) {
  
  .hideOnMobile {
    display: none;
  }
  .onMobileTogg {
    display: inherit;
  }
  }
  
  @media (min-width: 768px) and (max-width: 1024px){
    .beforeLoginHeader {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 999;
      background: #000000;
      .headerHolo {
        margin-top: 15px!important;
        margin-left: 15px!important;
      }
    }
    .welcomeHeader {
      color: #ffffff;
      font-size: 10.5px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      opacity: 0.8;
      margin-bottom: 0;
      margin-top: -15px;
      margin-right: -200px;
    }
  }
  
  */
  