h1.color-black.option-heading {
    font-size: 16px;
    font-weight: 700;
    padding: 5px 0px;
}
.radio-options-bg.col .row:nth-child(even) {
    background: #cccccc59;
}
.continue-button.col a {
    background: #000000;
    border: 1px solid #000000;
    color: #fff;
    font-size: 18px;
    padding: 8px 15px;
    border-radius: 4px;
    text-decoration: none;
}
.continue-button.col a:hover {
    background: #262525;
}
.omrtextbox.row input[type="text"] {
    width: 100%;
    margin: 10px auto;
    border: 1px solid #000;
}
.dcss.row label.form-check-label {
    padding: 0px 7px;
}
.textbxomr.row input[type="text"] {
    width: 100%;
    outline: none;
    margin-bottom: 0.5rem;
}
.radio-options-bg .row {
    padding-top: 0.5rem;
}
.omrdot.row {
    padding-bottom: 0.5rem;
}

.custom-radio-css .form-check input {
    cursor: pointer;
    display: none;
}
.custom-radio-css .form-check label {
    font-weight: 800;
    position: relative;
    display: inline-block;
    background: #ffffff;
    border: 2px solid #000;
    color: #000000;
    padding: 0 4px;
    border-radius: 100%;
    cursor: pointer;
    font-size: 12px;
    margin-left: -30px;
}
.custom-radio-css .form-check input:checked ~ label {
    color: #000000;
    border: 2px solid #000;
    background: #000000d4;
}
.textbxomr.row .col {
    margin-left: -30px;
    margin-right: 5px;
}
.textbxomr.row {
    border-bottom: 1px solid;
    border-top: 1px solid;
}
.continue-button.col button {
    background: #c83232;
    border: 1px solid #c83232;
    color: #ffffff;
    padding: 5px 15px;
    border-radius: 4px;
}
.omrdot.dcss.notAvail.row label.form-check-label {
    padding: 2px 3px;
    margin-left: -33px;
}
.omrdot.dcss.twodts.row .col:nth-child(2) {
    margin-left: -3px;
}
.omrdot.dcss.twodts.row .col:nth-child(3) {
    margin-left: -65px;
}
.textbxomr input {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 800px){

.textbxomr.row .col {
    margin-left: -10px;
    margin-right: 5px;
}

}